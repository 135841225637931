<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div style="background-color: #ffc5c5; padding: 5px" v-if="haveNoti">
      {{ $t("depreciation.confirm_action_noti") }}
    </div>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>
    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="$bvModal.show('export-modal')"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p>{{ $t("depreciation.export_file") }}</p>
            </button>
            <p class="m-0 me-3">
              {{ $t("depreciation.info_date") }}
            </p>
            <date-picker
              class="inline-block h-full"
              locale="en"
              v-model="infoDate"
              is-required
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div
                  class="input-arrow me-3 my-1 d-flex align-items-center"
                  @click="togglePopover"
                >
                  {{ inputValue | formatDate }}
                </div>
              </template>
            </date-picker>
          </div>
          <div class="d-flex">
            <div
              class="search-box d-flex"
              style="border-radius: 10px; margin-right: 10px"
            >
              <img src="@/assets/search-white.svg" width="20px" />
              <input
                type="text"
                v-debounce:700ms="searchByText"
                :placeholder="$t('home.search')"
                v-model="searchText"
              />
            </div>
            <button
              class="filter"
              style="border: none"
              type="button"
              id="button-addon2"
              @click="showFilter = true"
            >
              <img src="../../assets/filter-white.svg" />
            </button>
          </div>

          <MainAssetFilter
            v-show="showFilter"
            @filterOptions="saveFilter"
            @closeFilter="showFilter = false"
          />
        </div>
        <MasterTable
          @selected-assets="setSelectedAsset"
          :assetData="getAllAssets"
          :otherColumn="getAllAssetOtherColumn"
          :page="currentPage"
          :perPage="perPage"
          rowSelection="none"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @change="loadAssetData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import moment from "moment";
import MasterTable from "../../components/Table/Depreciation/MasterDepreciationTable.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { mapGetters } from "vuex";
import baseUrl from "../../util/backend";
import MainAssetFilter from "../../components/MainAssetFilter.vue";
export default {
  name: "DepreciationAsset",
  components: {
    MasterTable,
    DatePicker,
    Loading,
    MainAssetFilter,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      infoDate: new Date(),
      selectedAsset: [],
      searchText: "",
      filterOptions: {},
      currentPage: 1,
      totalRows: 1,
      perPage: 50,
      showFilter: false,
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      getAllAssets: "getAllAssetsStore",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      assets_count: "assets_count",
      companyName: "companyName",
      haveNoti: "haveDpNotification",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
  },
  watch: {
    infoDate() {
      this.loadAssetData();
    },
  },

  methods: {
    async onExport(isMail) {
      this.isLoading = true;
      if(this.assets_count != 0){
        try {
          const res = await this.$store.dispatch("ExportAsset", {
            request_date: moment(this.infoDate).toISOString(),
            is_email: isMail,
            company_id: this.company_id,
            filterOptions: this.filterOptions,
            filename: `${this.$t("asset.as_regdoc")}_${
              this.companyName
            }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
            type: "docRegisAsset",
            selected_assets: [],
          });
          this.isLoading = false;
        } catch (err) {
          console.log(err);
          this.isLoading = false;
        }
      } else {
        alert(this.$t("depreciation.depreciation_not_found"));
        this.isLoading = false;
      }
    },
    setSelectedAsset(value) {
      this.selectedAsset = value;
    },
    async searchByText() {
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      this.loadAssetData();
    },
    async loadAssetData() {
      this.selectedAsset = [];
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      await this.$store.dispatch("filterAllAsset_pagination", {
        filterOptions: this.filterOptions,
        saveToStore: false,
        countingStatus: this.countingStatus,
        page: this.currentPage,
        company_id: this.company_id,
        limit: this.perPage,
        request_date: moment(this.infoDate).toISOString(),
      });
      this.totalRows = this.assets_count;
      this.isLoading = false;
    },
    saveFilter(val) {
      this.showFilter = false;
      this.filterOptions = val;
      if (val.is_clear_filter) {
        this.searchText = "";
      }
      this.searchByText();
    },
  },
  mounted() {
    this.loadAssetData();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }
}
#button-addon2 {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid #7c7c7c;
  img {
    width: 40px;
    padding: 0.3rem !important;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}
</style>
